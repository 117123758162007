@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  50% { border-color: transparent; }
}

.spinner {
  font-weight: bold;
  color: #555;
}

.image-cropped {
  width: 260px;
  height: 260px;
  object-fit: cover;
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
  margin: 10px auto;
}

.image-cropped img {
  height: 100%;
  width: auto;
  display: block;
}

body, select, .caption, select, option  {
  font-family: 'Roboto Mono', monospace !important;
}

h1, h2, h3, h4 {
  font-family: 'Press Start 2P', monospace;
}

.comments {
  background-color: black;
  color: limegreen;
  font-family: 'Courier New', monospace;
  padding: 10px;
  border-radius: 5px;
  white-space: pre-line;
}

.comments div {
  margin-bottom: 20px
}

/* Larger Dropdown Styles */
.large-dropdown {
  font-size: 18px !important; /* Increase the font size */
  padding: 10px !important;  /* Add more padding */
  width: 100% !important;    /* Make it take the full width of its container */
}

h5 {
  font-size: 18px !important; /* Increase the font size */
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 768px) {
  .large-dropdown {
    font-size: 15px !important; /* Even larger font size for mobile */
    padding: 15px !important;  /* Increased padding for better touch interaction */
  }
  h5 {
    font-size: 15px !important; /* Even larger font size for mobile */
  }
}



@keyframes flash {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.flashing {
  animation: flash 1s infinite;
}

.green-text {
  color: green;
}

.fixture-block {
  margin: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 25px 10px 10px;
  width: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-height: 130px;;
}

.fixture-block:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.accordion {
  margin-bottom: 30px;
}

.section-title-euro {
  color: blue;
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
  margin-top: 30px;
}

.section-title {
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
  margin-top: 30px;
}

@keyframes flash-select {
  0%, 100% { background-color: transparent; }
  50% { background-color: grey; }
}

.flash-select {
  animation: flash-select 0.5s linear 3; /* Flash three times */
}

.loading-container {
  margin-top: 60px;
  margin-bottom: 60px;
}

@keyframes fade {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
}

.analyse-note {
  animation: fade 2s ease-in-out infinite;
}