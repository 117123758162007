body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flag-container {
  position: absolute;
  bottom: 10px; /* Adjust bottom margin if needed */
  width: 50px; /* Width of the flag */
  left: 50%; /* Center horizontally relative to the parent */
  transform: translateX(-50%); /* Shift left by half of its own width to center perfectly */
}

.image-cropped {
  position: relative;
  display: inline-block;
  width: 100%; /* Ensure the image container takes up all horizontal space */
}